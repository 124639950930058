.root {

}

.container {

}

.content {
  min-height: 240px;
}

@media (min-width: 800px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.original-image-container {
  text-align: center;
  height: 280px;
}

.cropped-image-container {
  text-align: center;
  width: 100%;
}

@media (max-width: 800px) {
  .cropped-image-container {
    height: 150px;
  }
}

.file-picker {
  margin: 15px 0;
}

.original-image {
  max-height: 230px;
  max-width: 230px;
}

.cropped-image {
  height: 90px;
  width: 90px;
}

.footer {
  text-align: center;
  margin-top: 25px;
}

.preview-text {
  margin-bottom: 10px;
  font-family: var(--font-semibold);
  color: var(--gray-d);
  font-size: 18px;
}

.preview-top {
  height: 20px;
}

.warning {
  height: 20px;
  color: var(--pri-04);
}

.btn {
  width: 150px;
  border-radius: 4px;
  height: 40px;
  padding: 0;
}

.placeholder {
  background-color: var(--gray-xl);
}

.aspect {
  height: 30px;
  width: 150px;
  background-color: var(--sec-01);
  border-radius: 4px;
  line-height: 30px;
}

.scale {
  border: 2px solid var(--gray-l);
  border-radius: 4px;
  padding: 5px;
  width: 60px;
  border-radius: 4px;
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 35px 0;
}

.edit {
  display: flex;
  gap: 10px;
  align-items: center;
}