.root {
  padding: 15px;
  max-width: 725px;
}

.item {
  margin-bottom: 20px;
}

.label {
  font-family: var(--font-semibold);
  margin-bottom: 8px;
}

.value {

}

.date-field-value {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.date-picker {
  width: 100px;
}

.btns {
  margin-left: 5px;
}

.date-field-value:hover .pencil {
  visibility: visible;
}

.pencil {
  transition: all 200ms;
  visibility: hidden;
  width: 14px;
  height: 14px;
  color: var(--pri-02);
  margin-left: 10px;
}

.field {
  min-height: 58px;
}

.empty {
  color: var(--gray-m);
  font-size: 14px;
  margin: 6px 0 0 0;
}