.content {
  display: flex;
  flex-direction: column;

  margin: 20px 0;
  /*230 is an arbitrary height to account for file header view*/
  height: calc(100vh - var(--navbar-height) - 230px);
}

@media (min-width: 1200px) {
  .content {
    flex-direction: row;
    justify-content: space-between;
  }
}

.col {
  position: relative;
}

@media (min-width: 1200px) {
  .col:not(:nth-of-type(1)) {
    flex: 1 1 300px;
    min-width: 300px;
    max-width: 300px;
    margin: 0 10px;
  }

  .col {
    display: flex;
    flex: auto;
    flex-direction: column;
  }
}