.card {
  border-bottom: 1px solid var(--sec-01);
  margin-bottom: 20px;

  min-width: inherit;
  max-width: inherit;
  box-sizing: border-box;
  background-color: var(--pri-01);
}

@media (min-width: 800px) {
  .card {
    border: 1px solid var(--sec-01);
    border-radius: 4px;
  }
}

.content {

}

.padded {
  padding: 15px;
}

.header {
  font-size: 20px;
  font-family: var(--font-semibold);
  color: var(--black);
}

.group-header {
  box-sizing: content-box;
  height: 60px;
  display: flex;
  align-items: center;

  padding: 0 15px;
}

.divider {
  height: 1px;
  min-height: 1px;
  width: 100%;
  background-color: var(--sec-01);
}

.middle {
  width: calc(100% - 30px);
  margin: 0 15px;
}

.sticky {
  position: sticky;
  top: calc(var(--navbar-height) + 20px);
}

.more {
  text-align: center;
  color: var(--pri-02);
  font-family: var(--font-semibold);
  padding: 15px;
  border-top: 1px solid var(--sec-01);
}