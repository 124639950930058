@font-face {
  font-family: 'HarmoniaSans W01';
  src: url('@/static/fonts/a5b66773-6a36-469a-8f45-e08448130bf2.eot?#iefix');
  src: url('@/static/fonts/a5b66773-6a36-469a-8f45-e08448130bf2.eot?#iefix')
      format('eot'),
    url('@/static/fonts/3ba56aa2-c01d-4bfc-aae4-eac13ee2ebbc.woff2') format('woff2'),
    url('@/static/fonts/51f1c13a-2c9b-4cba-9cbf-93317cbd3e10.woff') format('woff'),
    url('@/static/fonts/7b11ae47-5126-4c06-8f61-fbee4b6a8461.ttf') format('truetype');
}

@font-face {
  font-family: 'HarmoniaSans W01 Italic';
  src: url('@/static/fonts/9aa36f2e-ec77-4f6c-9698-ed44812c5b2f.eot?#iefix');
  src: url('@/static/fonts/9aa36f2e-ec77-4f6c-9698-ed44812c5b2f.eot?#iefix')
      format('eot'),
    url('@/static/fonts/858f50d9-f514-4dfa-8c90-310324ae3bbc.woff2') format('woff2'),
    url('@/static/fonts/63546f2e-ec1b-481d-93af-81d9530d6686.woff') format('woff'),
    url('@/static/fonts/71109fca-9801-4be6-9b53-3671d1e01e51.ttf') format('truetype');
}

@font-face {
  font-family: 'HarmoniaSans W01 SemiBd';
  src: url('@/static/fonts/cd8a7ca1-a1a1-4902-9824-18890229b553.eot?#iefix');
  src: url('@/static/fonts/cd8a7ca1-a1a1-4902-9824-18890229b553.eot?#iefix')
      format('eot'),
    url('@/static/fonts/08591efc-f43b-4dc0-b066-e39bb77fd0d9.woff2') format('woff2'),
    url('@/static/fonts/bb2822e9-2055-4c95-bd66-438a33d71a60.woff') format('woff'),
    url('@/static/fonts/1796e78c-3f90-4d62-a510-d2f1ebc88d86.ttf') format('truetype');
}

@font-face {
  font-family: 'HarmoniaSansW01-SemiBdI';
  src: url('@/static/fonts/922fefb6-cf43-454a-812d-524362ae522f.eot?#iefix');
  src: url('@/static/fonts/922fefb6-cf43-454a-812d-524362ae522f.eot?#iefix')
      format('eot'),
    url('@/static/fonts/26998c9c-98b7-46e1-a2f9-391cb0bcc27c.woff2') format('woff2'),
    url('@/static/fonts/b28a746b-254c-4d88-ac9d-aa7c5cdcebae.woff') format('woff'),
    url('@/static/fonts/bbc4c213-7a42-4e35-b2fa-869ca186e5a6.ttf') format('truetype');
}

@font-face {
  font-family: 'HarmoniaSans W01 Bold';
  src: url('@/static/fonts/afb7b057-b4c8-4f37-9611-323efb16599e.eot?#iefix');
  src: url('@/static/fonts/afb7b057-b4c8-4f37-9611-323efb16599e.eot?#iefix')
      format('eot'),
    url('@/static/fonts/d13c38b0-30e7-47f0-bc36-e2b7752b59e3.woff2') format('woff2'),
    url('@/static/fonts/50cd1762-b122-47c3-bce4-8e385f6a6db0.woff') format('woff'),
    url('@/static/fonts/2becde0a-efb7-469b-a992-fcebff7f02bb.ttf') format('truetype');
}

@font-face {
  font-family: 'HarmoniaSansW01-BoldIta';
  src: url('@/static/fonts/e42e6ecd-3b58-4896-b669-46559ae64595.eot?#iefix');
  src: url('@/static/fonts/e42e6ecd-3b58-4896-b669-46559ae64595.eot?#iefix')
      format('eot'),
    url('@/static/fonts/67be2cc4-236d-40b5-bf46-12c75a2679ff.woff2') format('woff2'),
    url('@/static/fonts/de3411e1-8034-40c1-a040-22ac43cf4831.woff') format('woff'),
    url('@/static/fonts/80645bed-0941-4f8b-837d-25e731464440.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('@/static/fonts/5664093/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix');
  src: url('@/static/fonts/5664093/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix')
      format('eot'),
    url('@/static/fonts/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2')
      format('woff2'),
    url('@/static/fonts/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff')
      format('woff'),
    url('@/static/fonts/5664093/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf')
      format('truetype');
}

@import url('./variables.module.css');
@import url('./reset.module.css');

@media (min-width: 800px){
  .root {
    min-width: 800px;
  }
}

html {
  box-sizing: border-box;
}