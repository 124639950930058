.root {
  display: flex;
}

.wrap {
  display: flex;
}

.main {
  padding: 10px 5px 10px 15px;
  border: 1px solid var(--gray-l);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: text;
}

.main:focus-within {
  border-color: var(--gray-m);
}

.header {

}

.prefix {
  font-size: 16px;
}

.input {
  font-family: var(--font-reg);
  border: none;
  font-size: 16px;
  width: 4.5em;
  padding-right: 5px;
  text-transform: uppercase;
}

.input::selection {
  background-color: var(--blue-l);
}

.color {
  display: flex;
  height: 41px;
  width: 41px;
  border-radius: 4px;
  margin-right: 10px;
}

.preview {
  width: 160px;
}