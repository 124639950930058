.cards {
  gap: 10px;
}

.card {
  height: 345px;
}

.title {
  height: 65px;
}