
.root {
  display: flex;
  flex-direction: column;

  height: 100%;
  max-width: 900px;
}

@media (min-widht: 800px) {
  margin: 0 auto;
}

@media (min-width: 1200px) {
  margin: 0;
}


.body {
  height: 100%;
  overflow-y: auto;
}

.download {
  cursor: pointer;
  color: var(--gray);
}

.download:hover {
  color: var(--pri-02);
}

.audio {
  box-sizing: border-box;
  background-color: var(--pri-01);
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
  padding: 10px;
}

.video-player {
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}

@media (min-width: 800px) {
  .video-player {
    max-width: 700px;
  }
}

.media-wrap {
  padding: 15px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-xl);
}

.loader {
  margin-left: 8px;
}