.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 6px;
}

.title {
  color: var(--gray-d);
  font-family: var(--font-bold);
  font-size: 18px;
}

.clear {
  color: var(--pri-02);
  cursor: pointer;
  font-family: var(--font-semibold);
  font-size: 14px;
}

.keyword {
  width: 75%;
  margin-bottom: 6px;
  box-sizing: border-box;
}

.keywords {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.selected {
  margin-bottom: 4px;
  box-sizing: border-box;
  overflow: hidden;
}

.selected:not(:last-child) {
  margin-right: 4px;
}

.selected > .action {
  overflow: hidden;
}

.selected > .action > .label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input {
  border: 2px solid var(--sec-01);
  border-radius: 7px;
  padding: 5px;
  font-size: 16px;
  font-family: var(--font-reg);
}