.bars {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4px;
  /*     background: black; */
}

.bar {
  bottom: 1px;
  width: 3px;
  margin: 0px 1px;
  border-radius: 5px;
  animation: sound 0ms -600ms linear infinite alternate;
}

.paused .bar {
  animation-play-state: paused;
}

@keyframes sound {
  0% {
    opacity: .35;
    height: 2px;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 474ms;
}

.bar:nth-child(2) {
  left: 15px;
  animation-duration: 433ms;
}

.bar:nth-child(3) {
  left: 29px;
  animation-duration: 407ms;
}

.bar:nth-child(4) {
  left: 43px;
  animation-duration: 458ms;
}

.bar:nth-child(5) {
  left: 57px;
  animation-duration: 400ms;
}

.bar:nth-child(6) {
  left: 71px;
  animation-duration: 427ms;
}

.bar:nth-child(7) {
  left: 85px;
  animation-duration: 441ms;
}

.bar:nth-child(8) {
  left: 99px;
  animation-duration: 419ms;
}

.bar:nth-child(9) {
  left: 113px;
  animation-duration: 487ms;
}

.bar:nth-child(10) {
  left: 127px;
  animation-duration: 442ms;
}