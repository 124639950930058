.root {

}

.wrap {

}

.main {

}

.row {
  display: flex;
  margin-bottom: 10px;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 190px;
  margin-right: 10px;
}

.text {

}

.header {
  color: var(--black-l);
  font-family: var(--font-bold);
  font-size: 18px;
  margin-bottom: 10px;
}

.indicator {
  height: 20px;
  width: 20px;
  color: var(--pri-03);
}

.enter {
  opacity: 1;
  transition: opacity 800ms ease;
}

.btn {
  font-family: var(--font-bold);
  width: 160px;
  height: 35px;
  border-radius: 3px;
}

.preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
}

.cols {
  display: flex;
  font-family: var(--font-bold);
  font-size: 15px;
  padding: 20px 0;
}

.name {
  width: 200px;
}

.hex {
  width: 160px;
}

.pre {
  width: 220px;
}

.bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.prompt {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  font-family: var(--font-reg);
  padding: 6px 8px;
  transition: background-color 0.2s;
  text-align: left;
}

.icon {
  height: 16px;
  width: 16px;
  font-size: 18px;
  margin-right: 4px;
}