.root {
  margin: 30px auto;
  height: calc(100vh - (var(--navbar-height) + 60px));
}

.wrap {
  display: flex;
  height: 100%;
  border: 1px solid var(--sec-01);
  border-radius: 7px;
  max-width: 700px;
  box-sizing: border-box;
  padding: 10px 35px;
  margin: 0 auto;
}

.main {
  margin: 20px 0;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--gray-l);
  box-shadow: 0 1px 0 0 var(--sec-01);
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-family: var(--font-bold);
  color: var(--black);
  line-height: 45px;
}

.row {
  margin-bottom: 30px;
}

.switch {
  width: 60px;
}

.switch.on {
  background-color: var(--pri-03);
}

.switch.off {
  background-color: var(--gray-l);
}

.toggle.off {
  background-color: var(--gray-m);
}

.toggle.on {
  background-color: var(--pri-01);
  border: 1px solid var(--sec-01);
}