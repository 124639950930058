#portal {
  z-index: var(--z-index-modal);
  border: 1px solid var(--gray-xl);
  border-radius: 0 0 7px 7px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
}

.container {
  display: flex;
}

.content {
  display: flex;
  justify-content: space-between;
  background-color: var(--pri-01);
  min-height: 270px;
  width: 800px;
}

.results {
  flex: 1;
  overflow: hidden;;
}

.search {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
  flex: 1;
  max-width: 400px;
  margin: 0 20px 0 30px;
}

.searchbar {
  display: block;
  width: 100%;
}