.file-layout-icon {
  color: var(--gray);
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.file-layout-icon:first-of-type {
  margin-right: 8px;
}

.file-layout-icon:hover {
  color: var(--gray-d);
}

.file-layout-icon-active {
  composes: file-layout-icon;
  background-color: var(--gray-xl);
}

.file-layout-icon-active:hover {
  color: var(--gray);
}